(function ($) {

    // $.proofguide('/wp-content/themes/pp-v1.2-children/kenny-saunders/resources/guide.jpg');

    setTimeout(function () {
        console.log($('.testimonial-flexslider .flex-control-nav'));
        $('.testimonial-flexslider .flex-control-nav').appendTo('#bcorr-placeholder');
    }, 600);

})(jQuery);

